<template>
  <div style="padding: 20px">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>
      <!--搜索条件-->
      <!-- <el-card shadow="never" style="margin-top: 20px">
        <div class="card">
          
        </div>
      </el-card> -->

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">数据列表</div>
              <el-button class="ml-min" type="primary"  v-auths="[`${$config.uniquePrefix}operate:accountmanagement:save`]" @click="add_account">
                添加账号
              </el-button>
            </div>
            <div></div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :total="total"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >
              <template v-slot:status="{ scope }">
                <div>
                  <img
                    style="cursor: pointer"
                    src="@/assets/images/home/yiqiyong.png"
                    alt=""
                    v-if="scope.row.status == 0"
                  />
                  <img
                    style="cursor: pointer"
                    src="@/assets/images/home/weiqiyong.png"
                    alt=""
                    v-else
                  />
                </div>
              </template>
              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:is_voucher="{ scope }">
                <el-link
                  :underline="false"
                  type="primary"
                  @click="pass(scope.row)"
                   v-auths="[`${$config.uniquePrefix}operate:accountmanagement:put`]"
                  >编辑</el-link
                >
                <el-link
                  slot="reference"
                  :underline="false"
                  type="danger"
                  v-if="scope.row.status == 0"
                  @click="EditStatus(scope.row)"
                    v-auths="[`${$config.uniquePrefix}operate:accountmanagement:status`]"
                  >停用</el-link
                >
                <el-link
                  slot="reference"
                  :underline="false"
                  type="danger"
                  v-if="scope.row.status == 1"
                  @click="EditStatus(scope.row)"
                     v-auths="[`${$config.uniquePrefix}operate:accountmanagement:status`]"
                  >启用</el-link
                >
                &nbsp;&nbsp;
                <el-popconfirm
                  title="确定执行此操作吗？"
                  @confirm="del(scope.row)"
                >
                  <el-link slot="reference" :underline="false" type="info"
                   v-auths="[`${$config.uniquePrefix}operate:accountmanagement:delete`]"
                    >删除</el-link
                  >
                </el-popconfirm>
              </template>
            </my-table>
          </div>
        </div>
      </el-card>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="账号名称">
          <el-input
            v-model="form.account"
            oninput="value=value.replace(/[\u4e00-\u9fa5]/g, '')"
            placeholder="请输入英文和数字"
          ></el-input>
        </el-form-item>

        <el-form-item label="账号密码">
          <el-input v-model="form.pwd" show-password></el-input>
        </el-form-item>

        <el-form-item label="确认密码">
          <el-input v-model="form.conf_pwd" show-password></el-input>
        </el-form-item>
        <el-form-item label="角色">
          <el-select
            v-model="form.roles"
            multiple
            collapse-tags
            placeholder="请选择"
          >
            <el-option
              v-for="item in cities"
              :key="String(item.id)"
              :label="item.name"
              :value="String(item.id)"
            >
            </el-option>
          </el-select>
          <!-- <el-checkbox-group v-model="form.roles">
            <el-checkbox v-for="item in 5" :label="item"></el-checkbox>
          </el-checkbox-group> -->
        </el-form-item>

        <el-form-item label="启用状态">
          <el-radio-group v-model="form.status">
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- <el-form-item label="角色名称">
          <el-input v-model="form.realname"></el-input>
        </el-form-item> -->

        <el-form-item label="账号身份">
          <el-input v-model="form.introduce"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="queding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 引入自定义表格组件
import MyTable from "@/components/MyTable";
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";

// 权限
import { ShowTable } from "@/utils";
// 引入的接口
import {
  withdrawalapplication,
  passid,
  refuses,
  get_superman,
  Delroles,
  Editstatus_zh,
  add_sup,
  xiu_sup,
  select_data,
} from "@/api/operate";

export default {
  name: "index",
  // 支柱道具属性
  props: {},
  // 组件生效
  components: {
    MyTable,
    PackageSortElement,
  },
  // Data数据
  data() {
    return {
      form: {
        roles: [],
      },
      datalis: {},
      //拒绝原因
      reason: "",
      //弹窗
      dialogVisibles: false,
      // 表格搜索条件
      //存储域名地址
      domain: "",
      withdraw_time: "",
      handle_time: "",
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      cities: [],
      value1: "",
      dialogVisible: false,
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading: true,
      //用来控制单条的数据
      row: {
        http: "",
        url: "",
      },
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label: "账号身份",
          prop: "introduce",
          isShow: true,
        },
        {
          label: "账号名称",
          prop: "account",
          isShow: true,
        },
        {
          label: "创建时间",
          prop: "create_time",
          isShow: true,
        },
        {
          label: "ip地址",
          prop: "last_ip",
          isShow: true,
        },
        {
          label: "登录次数",
          prop: "login_count",
          isShow: true,
        },
        {
          label: "启用状态",
          prop: "status",
          slot: "status",
          width: "100",
          // 权限判断
          // auths: ShowTable([`${this.$config.uniquePrefix}custom:set_status`]),
          isShow: true,
        },
        {
          width: "200",
          label: "操作",
          prop: "is_voucher",
          slot: "is_voucher",
          isShow: true,
        },
      ],
      flag: true,
      // 列表设置弹窗
      ListSetupShow: false,
    };
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getList();
    select_data().then((res) => {
        if (res.code == 200) {
          // this.$Message.success(res.msg);
          this.cities = res.data;
        } else {
          this.$Message.error(res.msg);
        }
      });
  },

  destroyed() {
    this.getList();
  },

  // 方法集合
  methods: {
    add_account() {
      this.form = {};
      this.flag = true;

      this.dialogVisible = true;
      //  this.cities = ;
      
    },
    queding() {
      if (this.flag) {
        add_sup(this.form).then((res) => {
          if (res.code == 200) {
            this.$Message.success(res.msg);
            this.getList();
            this.form = {};
            this.dialogVisible = false;
          } else {
            this.$Message.error(res.msg);
          }
        });
      } else {
        xiu_sup(this.form.id, this.form).then((res) => {
          if (res.code == 200) {
            this.$Message.success(res.msg);
            this.getList();
            this.form = {};
            this.dialogVisible = false;
          } else {
            this.$Message.error(res.msg);
          }
        });
      }
    },
    getList() {
      get_superman(this.where)
        .then(async (res) => {
          this.List = res.data.list;
          this.total = res.data.count;
          console.log(this.List);
          this.loading = false;
        })
        .catch((res) => {
          this.$Message.error(res.msg);
          this.loading = false;
        });
    },
    untie(value, row) {},
    unties() {},

    // 查询条件
    QueryList() {
      this.getList();
    },
    // 清空
    empty() {
      this.where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.getList();
    },
    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 点击列表设置
    ListSetup() {
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose() {
      this.ListSetupShow = false;
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    pass(row) {
      this.flag = false;
      this.dialogVisible = true;
      this.form = { ...row };
    },
    currentChange(val) {
      console.log("当前页数", val);
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection);
      console.log("选择的所有数据行", selection);
      console.log("当前选择的数据行", row);
    },
    ChangeSize(val) {
      console.log("当前页面显示的条数", val);
      this.where.limit = val;
      this.getList();
    },
    refuse() {
      let data = {
        reason: this.reason,
      };
      refuses(this.datalis.id, data).then((res) => {
        if (res.code == 200) {
          this.getList();
          this.$Message.success(res.msg);
          this.dialogVisibles = false;
        } else {
          this.$Message.error(res.msg);
        }
      });
    },
    refa(row) {
      this.datalis = row;
      this.dialogVisibles = true;
      this.reason = "";
    },
    del(row) {
      Delroles(row.id)
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.getList();
          } else {
            this.$message.success(res.msg);
          }
        })
        .catch((e) => {
          this.$message.error(e.msg);
        });
    },
    EditStatus(row) {
      let that = this;
      this.$utils.confirm(
        "确定执行此操作吗？",
        function () {
          // 执行一些需要的逻辑
          Editstatus_zh(row.id)
            .then((res) => {
              if (res.code === 200) {
                that.$message.success(res.msg);
                that.getList();
              } else {
                that.$message.success(res.msg);
                that.getList();
              }
            })
            .catch((e) => {
              that.$message.error(e.msg);
            });
        },
        "确定",
        "warning"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 20px;
}

.condition {
  .condition_t {
    display: flex;
    align-items: center;
  }
  .condition_t_icon {
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text {
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.w-50 {
  width: 400px;
}
.youbianjudingshirenwu {
  margin-left: 15px;
}
.zuobianjudingshirenwu {
  margin-right: 15px;
}
.ml-min {
  margin-left: 20px;
}
.text_c {
  color: #feae37;
}
</style>
